@import "../variables";

.footer-area{
    display:flex;
    height: fit-content;
    flex-wrap: wrap;
    background-color:rgb(15,15,15);
    align-items: center;
    justify-content: center;
    color:$white;
    flex-wrap: wrap;
    padding: 70px 10px 0 10px;
    list-style: none;

    .container{
        display:flex;
        flex-direction: column;
        max-width: 1000px;

        .footer-area-cards{
            display:flex;
            flex-direction: row;
            justify-content: space-around;
            max-width: 1000px;

            .card{
                display:flex;
                flex-direction: column;

                .card-naslov{
                    font-size: 22px;
                }

                .card-linija{
                    width: 40px;
                    height: 1px;
                    background-color: $tntcolor;
                }

                .mreza{
                    width: 30px;
                    margin:20px 10px 0 0;
                }

                .lista{
                    display: flex;
                    flex-direction: row;
                }

                .lista-brojeva{
                    display:flex;
                    flex-direction: column;
                }

                .drzac-slike-i-imena{
                    display:flex;
                    flex-direction: row;
                }

                .drzac-imena{
                    display:flex;
                    flex-direction: column;
                }

                .tekst3{
                    font-size: 15px;
                    color: gray;
                    margin-top: 13px;
                }

                .tekst2{
                    font-size: 15px;
                    color: gray;
                    margin-top: 0px;
                }

                .icon{
                    width: 30px;
                    height: 30px;
                   // height: 100%;
                    margin:20px 10px 0 0;
                    //background-color: rgb(90, 90, 90);
                   // filter:brightness(100)
                }

                .tekst{
                    font-size: 15px !important;
                    color: gray;
                    margin-top: 23px;
                }
            }
        }

        .footer-area-copyright{
            //background-color: $black;
            border-top:1px solid rgba(90, 90, 90, 0.521);
            display:flex;
            flex-direction: column;
            justify-content: space-around;
            align-items: center;
            margin:60px 0 0 0;
            padding-bottom: 40px;

            .copyright-tekst{
                margin-top: 15px;
                color:gray;
            }

            .sicef-logo{
                width: 60px;
                margin-top: 20px;
            }

            .sponzori-logo{
                display:flex;
                flex: 1;
                filter: brightness(0) invert(1) brightness(40%);
                max-width: 90px;
                margin-top:40px;
                margin-left: 20px;
                margin-right: 20px;
            }

            .sponzori{
                justify-content: center;
                display:flex;
                flex-wrap:wrap;
                //align-items: flex-end;
                align-items: center;
                //flex-direction: row;
            }
        }
    }
}

@media (max-width:768px){
    .footer-area{
        padding-top: 30px;
    }

    .footer-area-cards{
        flex-direction: column !important;

        .card{
            margin-bottom: 20px;
        }
    }
}
@import "../variables";

.lecturers-list{
    max-width: 1000px;
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.lecturers-list-row{
    display:flex;
    flex-direction: row;
}

@media (max-width: 1000px) {
    .lecturers-list-row{
        flex-direction: column;
    }
}
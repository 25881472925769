@import "../variables";

.counter-area{
    height: fit-content;
    background:url('../../assets/pattern2.jpg');
    background-repeat: repeat;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    padding: 100px 0 100px 0;
    z-index: 50;
}
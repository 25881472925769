@import "../variables";

.side-drawer{
    height: 100%;
    width: 70%;
    max-width: 400px;
    background: url('../../assets/pattern1.jpg');
    background-repeat: repeat;
    position:fixed;
    
    top:0;
    left:0;
    z-index: 200;
    transform: translateX(-100%);
    transition:transform 0.5s ease-out;

    ul{
        height: 100%;
        list-style: none;
        display:flex;
        flex-direction: column;
        justify-content: center;
        font-size:16px;
    }

    li{
        margin:30px;
    }

    a{
        color:$white;
        text-decoration: none !important;
    }
}

.side-drawer a:hover,
.side-drawer a:active{
    color:$tntcolor;
    transition: 0.5s;
}

.side-drawer.open{
    transform: translateX(0);
    box-shadow: 15px 15px 25px rgba(0, 0, 0, 0.5);
}

@media (min-width:769px){
    .side-drawer{
        display:none;
    }
}
@import "../variables";

.slider{
    max-width: 1000px;
    margin:40px auto 0 auto;
    padding-top: 50px;
    padding-bottom: 10px;
}

.slika{
    max-height: 150px;
    //margin: auto 0 auto 0 !important;
}

@media (max-width:767px){
    .slider{
        margin-top: 0px !important;
    }

    .slika{
        max-width: 120px !important;
        max-height: 80px;
        margin:0 20px 0 20px;
    }

    .slika-container{
        width: 300px !important;
        margin-left: 20px !important;
        margin-right: 20px !important;
    }
}


@import "../variables";

.agenda-area{
    height: fit-content;
    background:linear-gradient($tntcolor2,$tntcolor3),url('../../assets/sicefslika.jpg');
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding-bottom: 60px;
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 1;
    color:$white;

    .button-holder{
        display:flex;
        flex-direction: row;
        max-width: 800px;
        align-items: center;
        justify-content: space-evenly;

        .agenda-area-button1{
            display:flex;
            width:400px;
            align-items: center;
            justify-content: space-around;
            margin-bottom: 50px;
            background-color: $tntcolor;
            color:$black;
            font-size: 20px;
            border: none;
            height: 70px;
            border-bottom-left-radius: 50px;
            box-shadow: 0px 15px 15px rgba(0, 0, 0, 0.15);
            transition: 0.5s;
            :focus{outline:0 !important;}
            z-index: 1;
            transition: 0ms;

            &:focus{
                outline: none !important;
            }
        }

        .agenda-area-button2{
            display:flex;
            width:400px;
            align-items: center;
            justify-content: space-around;
            margin-bottom: 50px;
            margin-top: -10px;
            background-color: $tntcolor2;
            color:$black;
            font-size: 20px;
            border: none;
            height: 60px;
            border-bottom-right-radius: 40px;
            box-shadow: 0px 15px 15px rgba(0, 0, 0, 0.15);
            transition: 0.5s;
            :focus{outline:0 !important;}
            z-index: 1;
            transition: 0ms;

            &:focus{
                outline: none !important;
            }
        }

        .active1{
            background-color: $tntcolor2;
            height: 60px;
            margin-top: -10px;
            transition: 0ms;
           
        }

        .active2{
            background-color: $tntcolor;
            height: 70px;
            border-bottom-right-radius: 50px;
            margin-top: 0px;
            transition: 0ms;
        }
    }
}

@media (max-width:768px){
    .agenda-area-button1{
        width: 150px !important;
    }
    .agenda-area-button2{
        width: 150px !important;
    }


}
@import "../variables";



.lecturers-card-wrapper{
    margin-top:40px;
    margin-right: 40px;
}

.lecturers-card{
    box-shadow: 7px 0px 0px $tntcolor,5px 15px 15px rgba(0, 0, 0, 0.3);
    color:$black;
    background:linear-gradient(rgba(247, 247, 247,1),rgba(211, 211, 211, 1)),;
    //margin: 40px 40px 0 ;
    border-top-left-radius: 80px;
    border-bottom-right-radius: 80px;
    overflow:hidden;
    width: fit-content;
    display:grid;
    grid-template-columns: 200px 250px;
    grid-template-rows: auto;
    //box-shadow: 0px 15px 15px rgba(0, 0, 0, 0.15);
    grid-template-areas: 
    "slika tekst"
    "ime ime"
    "firma firma";

    .lecturers-card-ime{
        grid-area:ime;
        text-align: center;
        font-size: 30px;
        background:linear-gradient(rgba(255, 255, 255, 0.5),rgba(211, 211, 211, 0.1));
    }

    .drzacSlike{
        box-shadow: 7px 0px 0px $tntcolor;
        max-width: 200px;
        max-height: 200px;
        border-bottom-right-radius: 40%;
        overflow: hidden;
    }

    .lecturers-card-slika{
        grid-area:slika;
        //filter: grayscale(100%);
        border-bottom-right-radius: 40%;
        width: 200px;
        transition:1s;
    }

    .lecturers-card-firma{
        grid-area:firma;
        text-align: center;
        font-size: 18px;
        margin-top:5px;
        margin-bottom: 10px;
    }

    .lecturers-card-tekst{
        grid-area:tekst;
        text-align: left;
        font-size: 14px;
        margin-top:10px;
        margin-left: 27px;
        margin-right: 20px;
    }
}

@media (max-width: 1000px) {
    .lecturers-card-wrapper{
        margin-right: 0px;
    }
}

@media (max-width: 500px) {
    .lecturers-card{
        border-top-left-radius: 80px;
        border-bottom-right-radius: 80px;
        width: 200px;
        grid-gap:20px 0;
        overflow: visible;
        height: auto;
        grid-template-columns: 200px;
        grid-template-rows:auto 130px auto auto;
        grid-template-areas: 
        "slika"
        "tekst"
        "ime"
        "firma";
    }

    .lecturers-card-firma{
        margin-top:-10px !important;
    }

    .lecturers-card-tekst{
        font-size: 14px !important;
        margin-top:0% !important;
        margin-right: 0px !important;
        margin-left: 15px !important;
        overflow-y: scroll;
        -webkit-overflow-scrolling: auto;
        -webkit-overflow-scrolling: touch; 
    }

    .drzacSlike{
        border-top-left-radius: 40%;
    }
}


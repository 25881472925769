@import "../variables";

.sponsors-page-list{
    display:flex;
    flex-direction: column;
    align-items: center;
    margin-top: 40px;
    padding-bottom: 60px;
    //justify-content: center;

    .naslov{
        display:flex;
        //align-items: center;
        //justify-content: center;
        font-size: 64px;
        margin-bottom: 40px;
       // text-align: center;
        color:$black;
    }

    .drzacdiva{
        display:flex;
        flex-wrap: wrap;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
    }
}

@media(max-width:850px){
    .drzacdiva{
        flex-direction: row !important;
    }
}